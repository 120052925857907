import '../scss/global.scss';
import 'bootstrap';

import 'jquery-hoverintent';
import 'superfish';

import 'jquery.fancybox/source/jquery.fancybox.css';
import 'jquery.fancybox';

(function () {
    $(".menu").superfish({
        delay: 500,
        animation: {
            opacity: 'show',
            height: 'show'
        },
        speed: 'fast',
        autoArrows: true
    });

    $('[data-url]').css('cursor', 'pointer');
    $('[data-url]').on('click', function () {
        window.location = $(this).data('url');
    });
    console.log($('[data-url]'));

    let $scroll = $("#scroll-up");

    $scroll.on("click", function () {
        $("html, body").animate({scrollTop: 0}, 800);
        return false;
    });

    function showHideScrollTop() {
        if ($(window).scrollTop() > $(window).height() / 2) {
            $scroll.fadeIn(300);
        } else {
            $scroll.fadeOut(300);
        }
    }

    showHideScrollTop();
    $(window).scroll(function () {
        showHideScrollTop();
    });

    function mobile_menu() {
        if ($(window).width() < 992) {
            if ($("#menu").length > 0) {
                if ($("#mobile-menu").length < 1) {
                    $("#menu").clone().attr({
                        id: "mobile-menu",
                        class: ""
                    }).insertAfter("#header");

                    $("#mobile-menu .megamenu > a").on("click", function (e) {
                        e.preventDefault();
                        $(this).toggleClass("open").next("div").slideToggle(300);
                    });

                    $("#mobile-menu .dropdown > a").on("click", function (e) {
                        e.preventDefault();
                        $(this).toggleClass("open").next("ul").slideToggle(300);
                    });

                    $("#mobile-menu .cart > a").on("click", function (e) {
                        e.preventDefault();
                        $(this).toggleClass("open").next("div").slideToggle(300);
                    });
                }
            }
        } else {
            $("#mobile-menu").hide();
        }
    }

    mobile_menu();
    $(window).resize(function () {
        mobile_menu();
    });

    $("#mobile-menu-button").on("click", function (e) {
        e.preventDefault();
        $("#mobile-menu").slideToggle(300);
    });

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    if (!getCookie('cookie_consent')) {
        $('.cookie-consent').show();
    }

    $('.cookie-consent .close').on('click', function (e) {
        e.preventDefault();
        setCookie('cookie_consent', 1);
    });

    $(".fancybox").fancybox();
}());

